// @flow
import React from 'react';

import Stack from '@mui/material/Stack';
import SubscribeScreen from './components/SubscribeScreen';

const PaymentsWorkspace = () => (
  <Stack justifyContent="center" alignItems="center" height="100%">
    <SubscribeScreen />
  </Stack>
);

export default PaymentsWorkspace;
