// @flow
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, useParams } from 'react-router-dom';
import ROUTES_PATH from 'domain/router/routesPathConfig';
import * as ACL from 'domain/restriction';
import CompanyContext from 'pages/company/CompanyContext';
import { addQueryToPath } from 'pages/configurations/company/helpers';

const useSettingLink = () => {
  const isGranted = useSelector(ACL.isGranted);
  const { companyType } = useContext(CompanyContext);
  const { companyId = '' } = useParams();

  const isPaymentsWorkspace = companyType === 'payments';

  const linksMapping = {
    company: [
      {
        role: ACL.IS_ADMIN,
        to: addQueryToPath(
          generatePath(ROUTES_PATH.COMPANY_SETTINGS_GENERAL_MAIN.absolute, { companyId }),
          isPaymentsWorkspace ? { type: companyType } : null,
        ),
      },
      {
        role: ACL.IS_ALLOW_SUPPLIER_FORM_FOR_BK_WITHOUT_ADMIN_PERMISSIONS,
        to: generatePath(ROUTES_PATH.COMPANY_SETTINGS_SUPPLIER_DEFAULTS.absolute, { companyId }),
      },
      {
        role: ACL.ALLOW_APPROVAL_GROUPS_FOR_BK,
        to: generatePath(ROUTES_PATH.COMPANY_SETTINGS_APPROVALS.absolute, { companyId }),
      },
    ],
    organization: [
      {
        role: ACL.IS_ADMIN,
        to: generatePath(ROUTES_PATH.ORGANIZATION_SETTINGS_GENERAL_MAIN.absolute),
      },
    ],
  };

  const links = companyId ? linksMapping.company : linksMapping.organization;
  const link = links.find((l) => isGranted(l.role));

  return link ? link.to : null;
};

export default useSettingLink;
