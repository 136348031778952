// @flow
import React, { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Api from 'domain/api';

import Dialog from 'components/mui/Dialog';
import Stack from '@mui/material/Stack';
import useToggle from 'hooks/useToggle';
import AirwallexConnectedAccountForm from 'components/Form/JsonDrivenForms/AirwallexConnectedAccountForm';
import Providers from './components/Providers';
import AccountsList from './components/AccountsList';

import { companyIdSelector } from 'domain/companies';

const hideErrors = 'ValidateAndHide';
const showErrors = 'ValidateAndShow';

const mapStateToProps = (state) => ({
  companyId: companyIdSelector(state),
});

const Accounts = () => {
  const [isOpenSubscribeModal, toggleIsOpenSubscribeModal] = useToggle();
  const [validationMode, setValidationMode] = useState(hideErrors);
  const [schemata, setSchemata] = useState({});
  const [hasErrors, setErrors] = useState(false);

  const { companyId } = useSelector(mapStateToProps);

  useEffect(async () => {
    const getSchemata = async () => await Api.paymentProviderSubscriptionFormSchemata();
    if (companyId) {
      const { data } = await getSchemata();
      setSchemata(data);
    }
  }, [companyId]);

  const { schema, uischema, data } = schemata;

  const onSubmit = useCallback(async () => {
    setValidationMode(showErrors);
    if (!hasErrors) {
      try {
        const result = await Api.createPaymentProviderSubscription({ data });
        // @to-do redirect to success
        toggleIsOpenSubscribeModal();
      } catch (error) {
        // consider moving setValidationMode(showErrors) here
      }
    }
  }, [data, toggleIsOpenSubscribeModal, hasErrors]);

  const onFormDataChanged = useCallback(({ data, errors }) => {
    setErrors(errors.length > 0);
    setValidationMode(hideErrors); // clear validation errors once user interracted with form
    setSchemata({ ...schemata, data });
  });

  return (
    <Stack spacing={4}>
      <Providers onConnect={toggleIsOpenSubscribeModal} />
      <AccountsList />
      <Dialog
        onOk={onSubmit}
        open={isOpenSubscribeModal}
        onClose={toggleIsOpenSubscribeModal}
        title="Subscribe to AirWallex Payment Provider"
        maxWidth="lg"
      >
        <AirwallexConnectedAccountForm
          schema={schema}
          uischema={uischema}
          data={data}
          onFormDataChanged={onFormDataChanged}
          validationMode={validationMode}
        />
      </Dialog>
    </Stack>
  );
};

export default Accounts;
