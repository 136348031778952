// @flow
import React from 'react';
import { useParams } from 'react-router-dom';

import CompanyPage from './index';

export default function Standard() {
  const params = useParams();

  return <CompanyPage type="payments" match={{ params }} />;
}
