// @flow
import type { AuthData } from './types';
import type { AxiosXHRConfig } from 'axios';

export const updateAuthRequestData = (requestData: AxiosXHRConfig, pathData: AuthData): AxiosXHRConfig => {
  const { token, lang, company, payment_provider } = pathData;
  const { headers = {}, ...rest } = requestData;

  const updateHeader = {
    Authorization: `Bearer ${token}`,
    'Accept-Language': lang,
  };

  const updateCompany = company
    ? {
      CompanyId: company,
    }
    : {};

  const updatePaymentProvider = payment_provider
    ? {
      'x-payment-provider': payment_provider,
    } 
    : {};

  return {
    ...rest,
    headers: {
      ...updateHeader,
      ...updateCompany,
      ...updatePaymentProvider,
      ...headers,
    },
  };
};
