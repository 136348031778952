// @flow
import React from 'react';
import { useIntl } from 'react-intl';

import Card from '@mui/material/Card';
import Avatar from '@mui/material/Avatar';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import BeenhereOutlinedIcon from '@mui/icons-material/BeenhereOutlined';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';

import { alpha, useTheme } from '@mui/material';

const items = [
  {
    Icon: AttachMoneyOutlinedIcon,
    title: {
      id: 'company.paymentsWorkspace.subscribe.item1.title',
      defaultMessage: 'Save Money',
    },
    description: {
      id: 'company.paymentsWorkspace.subscribe.item1.description',
      defaultMessage: 'Up to $10,000 credit limit for your team to fund anything form fuel to inventiry',
    },
  },
  {
    Icon: BeenhereOutlinedIcon,
    title: {
      id: 'company.paymentsWorkspace.subscribe.item2.title',
      defaultMessage: 'Spend Confidently',
    },
    description: {
      id: 'company.paymentsWorkspace.subscribe.item2.description',
      defaultMessage: 'Up to $10,000 credit limit for your team to fund anything form fuel to inventiry',
    },
  },
  {
    Icon: TimerOutlinedIcon,
    title: {
      id: 'company.paymentsWorkspace.subscribe.item3.title',
      defaultMessage: 'Save Time',
    },
    description: {
      id: 'company.paymentsWorkspace.subscribe.item3.description',
      defaultMessage: 'Seamlessly synchronized to QuickBooks to save time and avoid human error',
    },
  },
];

const Cards = () => {
  const { formatMessage } = useIntl();
  const { palette } = useTheme();

  return (
    <Stack direction="row" justifyContent="space-between">
      {items.map(({ Icon, title, description }) => (
        <Card sx={{ maxWidth: 330 }} key={title.id}>
          <Stack justifyContent="center" alignItems="center" p={2}>
            <Avatar sx={{ bgcolor: alpha(palette.primary.main, 0.12) }}>
              <Icon color="primary" />
            </Avatar>
          </Stack>
          <CardContent sx={{ textAlign: 'center' }}>
            <Typography variant="h6">{formatMessage(title)}</Typography>
            <Typography variant="body1">{formatMessage(description)}</Typography>
          </CardContent>
        </Card>
      ))}
    </Stack>
  );
};

export default Cards;
