// @flow
import { selector } from 'lib/selectors';

import { Statuses } from 'pages/configurations/company/pages/accounts/components/Providers/helpers';

export const payments = (state) => state.payments;

export const paymentProvidersSelector = selector(payments, (p) => p.providers);
const accountsSelector = selector(payments, (p) => p.accounts);

export const firstActivePaymentProviderNameSelector = selector(
  paymentProvidersSelector,
  (p) => p.find((item) => item.status === Statuses.active)?.payment_provider,
);

export const isConnectedPaymentProviderSelector = selector(paymentProvidersSelector, (p) =>
  p.some((item) => item.status === Statuses.active),
);

export const paymentAccountsSelector = selector(accountsSelector, (a) => a.list.toJS());
export const paymentAccountsGridHeadersListSelector = selector(accountsSelector, (a) => a.gridHeadersList.toJS());
