// @flow
import React from 'react';
import { useIntl } from 'react-intl';
import { generatePath, Link, useParams } from 'react-router-dom';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import WindowImage from './images/Window.svg';
import Cards from './components/Cards';

import { addQueryToPath } from 'pages/configurations/company/helpers';
import ROUTES_PATH from 'domain/router/routesPathConfig';

import { useTheme } from '@mui/material';

const SubscribeScreen = () => {
  const { palette } = useTheme();
  const { formatMessage } = useIntl();
  const { companyId } = useParams();

  const link = addQueryToPath(generatePath(ROUTES_PATH.COMPANY_SETTINGS_ACCOUNTS.absolute, { companyId }), {
    type: 'payments',
  });

  return (
    <Stack
      sx={{
        background: `url("${WindowImage}") scroll no-repeat 100% 0/contain`,
        backgroundSize: 'auto 55%',
        maxWidth: 1080,
        maxHeight: 700,
        width: '100%',
      }}
      justifyContent="space-between"
      height="100%"
    >
      <Stack maxWidth={500} spacing={2} alignItems="flex-start">
        <Typography
          sx={{
            background: `linear-gradient(90deg, ${palette.primary.main} 0%, ${palette.secondary.main} 25%)`,
            backgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
          }}
        >
          {formatMessage({ id: 'company.paymentsWorkspace.title', defaultMessage: 'DOKKA PAY' })}
        </Typography>
        <Typography variant="h4" color="primary.dark">
          {formatMessage({
            id: 'company.paymentsWorkspace.subTitle',
            defaultMessage: 'You haven’t subscribed to this service yet',
          })}
        </Typography>
        <Typography variant="body1">
          {formatMessage({
            id: 'company.paymentsWorkspace.description',
            defaultMessage:
              'Ready to streamline your vendor payments? Simply subscribe to our service and we\'ll create your dedicated payment account. This account will allow you to easily pay your vendors with our secure platform. Click "Subscribe" to get started!',
          })}
        </Typography>
        <Button component={Link} to={link}>
          {formatMessage({ id: 'button.subscribe', defaultMessage: 'Subscribe' })}
        </Button>
      </Stack>
      <Cards />
    </Stack>
  );
};

export default SubscribeScreen;
