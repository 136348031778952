import { makeStyles } from '@mui/styles';
import { GRID_SERVICE_KEYS } from 'domain/documents/helpers';

export default makeStyles((theme) => {
  const isRtl = theme.direction === 'rtl';
  const LEFT = isRtl ? 'right' : 'left';
  const RIGHT = isRtl ? 'left' : 'right';

  return {
    '@global': {
      '.ag-theme-material': {
        '&& .ag-header': {
          backgroundColor: 'var(--ag-background-color)',
          marginBottom: 0,
          border: 'none',
          '& .ag-header-row': {
            '& .ag-header-cell': {
              border: 'none',
              backgroundColor: 'var(--ag-background-color) !important',
            },
          },
        },
        '&& .ag-row:not(.ag-row-hover) .action-btns': {
          opacity: 1,
        },
        '&& .ag-pinned-right-cols-container .ag-row .ag-cell': {
          border: 'none !important',
        },

        [`&& .ag-pinned-${RIGHT}-cols-container .ag-row .ag-cell.ag-cell-last-${RIGHT}-pinned`]: {
          boxShadow: 'none',
        },

        [`&& .ag-pinned-${RIGHT}-cols-container .ag-row .ag-cell.ag-cell-first-${RIGHT}-pinned`]: {
          boxShadow: 'none',
        },

        '& .ag-row.ag-row-level-0.ag-row-hover .ag-cell': {
          backgroundColor: 'var(--ag-row-hover-color) !important',
        },

        '&& .ag-column-hover': {
          backgroundColor: 'inherit',
        },

        '&& .ag-row.ag-row-level-0': {
          border: 'none',
          borderTop: '1px solid var(--ag-border-color)',
          maxHeight: 64,
          '& .ag-cell': {
            lineHeight: 'var(--ag-internal-calculated-line-height)',
          },
        },

        '&& .ag-body-horizontal-scroll.ag-scrollbar-invisible': {
          pointerEvents: 'all !important',
        },

        '&& .ag-status-bar': {
          display: 'none',
        },
      },
    },
  };
});
