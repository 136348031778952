export const PAYMENT_PROVIDERS_NAMES = Object.freeze({
  airwallex: 'airwallex',
});

export const PAYMENT_PROVIDERS_TITLES = {
  [PAYMENT_PROVIDERS_NAMES.airwallex]: 'AirWallex',
};

export const Statuses = {
  active: 'ACTIVE',
  disconnected: 'DISCONNECTED',
  created: 'CREATED',
  actionRequired: 'ACTION_REQUIRED',
  submitted: 'SUBMITTED',
  suspended: 'SUSPENDED',
};
