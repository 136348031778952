// @flow
import React, { useContext, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, useParams, useLocation } from 'react-router-dom';
import ROUTES_PATH from 'domain/router/routesPathConfig';
import CompanyContext from 'pages/company/CompanyContext';
import { LayoutCompanyContext } from 'components/mui/Layouts/Company/LayoutCompanyContext';
import RouterNavLink from 'components/mui/Router/RouterNavLink';
import { categoriesTreeSelector } from 'domain/categories/selectors';
import * as ACL from 'domain/restriction';
import { FormattedMessage } from 'react-intl';

import Categories from 'pages/company/CompanyNavigation/Categories';
import FavoritesMenu from 'pages/company/CompanyNavigation/FavoritesMenu';
import type { DocDropParams } from 'pages/company/type.js.flow';
import { DnDItemTarget, DOCUMENT, LINKED, LINKED_DOCUMENT } from 'components/DnDItem';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { ListItemIcon } from 'components/mui/Layouts/components/Drawer';
import { ListItemWrapper, ListItemButton } from 'pages/company/CompanyNavigation/StyledComponents';

import ViewCarouselOutlinedIcon from '@mui/icons-material/ViewCarouselOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';

type TCompanyNavigation = {
  onLinkingSidebarClose: () => void,
  onDocMove?: (p: DocDropParams) => void,
};

const DropTarget = DnDItemTarget([DOCUMENT, LINKED, LINKED_DOCUMENT]);

const identity = (x) => x;

const mapStateToProps = (state) => ({
  categoriesTree: categoriesTreeSelector(state),
  restriction: ACL.myRestriction(state),
});

const CompanyNavigation: React$StatelessFunctionalComponent<TCompanyNavigation> = ({
  onDocMove,
  onLinkingSidebarClose,
}) => {
  const { categoriesTree, restriction } = useSelector(mapStateToProps);
  const { toggle, onToggle } = useContext(LayoutCompanyContext);
  const { companyType } = useContext(CompanyContext);
  const params = useParams();
  const { pathname } = useLocation();

  const isActiveRoute = (path) => generatePath(path, params).split('?')[0] === pathname;

  const navigationsList = [
    {
      Icon: ViewCarouselOutlinedIcon,
      id: 'company.workspace',
      displayName: 'Workspace',
      to: generatePath(ROUTES_PATH.COMPANY_WORKSPACE.absolute, params), // use category1 from route params
      restriction: ROUTES_PATH.COMPANY_WORKSPACE.restriction,
      type: 'standard',
      isActive: isActiveRoute(ROUTES_PATH.COMPANY_WORKSPACE.absolute),
    },
    {
      Icon: LockOutlinedIcon,
      id: 'company.confidentialWorkspace',
      displayName: 'Confidential',
      to: generatePath(ROUTES_PATH.COMPANY_CONFIDENTIAL_WORKSPACE.absolute, params),
      restriction: ROUTES_PATH.COMPANY_CONFIDENTIAL_WORKSPACE.restriction,
      type: 'confidential',
      isActive: isActiveRoute(ROUTES_PATH.COMPANY_CONFIDENTIAL_WORKSPACE.absolute),
    },
    {
      Icon: PaymentsOutlinedIcon,
      id: 'company.paymentsWorkspace',
      displayName: 'DOKKA Pay',
      to: generatePath(ROUTES_PATH.COMPANY_PAYMENTS_WORKSPACE.absolute, params),
      restriction: ROUTES_PATH.COMPANY_PAYMENTS_WORKSPACE.restriction,
      type: 'payments',
      isActive: isActiveRoute(ROUTES_PATH.COMPANY_PAYMENTS_WORKSPACE.absolute),
    },
  ];

  // expand sidebar navigation when we start drag document to another category
  const onDragOver = useCallback(() => {
    if (!toggle) {
      onToggle();
    }
  }, [toggle, onToggle]);

  return (
    <>
      <List
        disablePadding
        sx={{ display: 'block' }}
        component={DropTarget}
        onDragOver={onDragOver}
        onDragLeave={onDragOver}
        onDragEnter={onDragOver}
      >
        {navigationsList.filter(ACL.arrayFilter(restriction)).map((nav) => (
          <ListItemWrapper isActive={companyType === nav.type} key={nav.id}>
            <ListItem
              component="div"
              sx={{
                p: 0,
              }}
            >
              <ListItemButton
                component={RouterNavLink}
                href={nav.to}
                selected={nav.isActive}
                onClick={onLinkingSidebarClose}
                isToggle={toggle}
              >
                <ListItemIcon
                  sx={{
                    mr: toggle ? 1 : 'auto',
                    justifyContent: 'center',
                    color: 'inherit',
                  }}
                >
                  <nav.Icon />
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  sx={{ display: toggle ? 'flex' : 'none', whiteSpace: 'normal', fontWeight: 500 }}
                >
                  <FormattedMessage id={nav.id} defaultMessage={nav.displayName} />
                </ListItemText>
                {companyType === nav.type ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
            </ListItem>
            {companyType === nav.type && (
              <Categories tree={categoriesTree} onDocMove={onDocMove} onCategoryClick={identity} />
            )}
          </ListItemWrapper>
        ))}
      </List>
      <FavoritesMenu />
    </>
  );
};

export default CompanyNavigation;
