// @flow
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { paymentProvidersSelector } from 'domain/payments';

import { PAYMENT_PROVIDERS_NAMES, PAYMENT_PROVIDERS_TITLES } from '../helpers';
import airwallexIcon from '../icons/airwallex.svg';
import AirwallexEmbededComponent from '../components/AirwallexEmbededComponent';

const providersConfig = {
  [PAYMENT_PROVIDERS_NAMES.airwallex]: {
    name: PAYMENT_PROVIDERS_NAMES.airwallex,
    title: PAYMENT_PROVIDERS_TITLES[PAYMENT_PROVIDERS_NAMES.airwallex],
    icon: airwallexIcon,
    KYCComponent: AirwallexEmbededComponent,
  },
};

const mapStateToProps = (state) => ({
  providers: paymentProvidersSelector(state),
});

const usePaymentProvidersConfig = () => {
  const { providers } = useSelector(mapStateToProps);

  const paymentProvidersConfig = useMemo(() =>
    providers.map(
      ({ payment_provider: provider, status }) => ({
        ...providersConfig[provider],
        status,
      }),
      [providers],
    ),
  );

  return { paymentProvidersConfig };
};

export default usePaymentProvidersConfig;
