// type @flow
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useIntl, FormattedMessage } from 'react-intl';
import { useParams, Outlet, generatePath, useLocation } from 'react-router-dom';
import { loadingSelector } from 'domain/env/envSelector';
import { organizationEnhancedUsersSelector } from 'domain/organizationUser/selectors';
import { erpsSelector } from 'domain/settings/settingsSelector';
import { companyFeatureSetSelector } from 'domain/companies';
import { checkUsers, checkErps, addQueryToPath } from 'pages/configurations/company/helpers';
import useToggle from 'hooks/useToggle';
import ROUTES_PATH from 'domain/router/routesPathConfig';
import * as ACL from 'domain/restriction';

import LayoutCompanySettings from 'components/mui/Layouts/CompanySettings';
import StickySubheader from 'components/mui/Layouts/components/StickySubheader';
import CircularProgressWithBackdrop from 'components/mui/CircularProgressWithBackdrop';
import DeleteModal from 'pages/configurations/company/components/DeleteModal';
import Tabs from 'components/Tabs';
import Tooltip from 'components/mui/Tooltip';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';

import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

const mapStateToProps = (state) => ({
  users: organizationEnhancedUsersSelector(state),
  featureSet: companyFeatureSetSelector(state),
  erps: erpsSelector(state),
  loading: loadingSelector(state),
  restrictions: ACL.myRestriction(state),
});

const Configurations = () => {
  const { users, featureSet, erps, loading, restrictions } = useSelector(mapStateToProps);
  const { formatMessage } = useIntl();
  const { fin } = featureSet;
  const { companyId, '*': asterisk } = useParams();
  const [tab, subTab] = asterisk.split('/');
  const [isOpenDelete, toggleIsOpenDelete] = useToggle(false);
  const ACLFilterFN = useMemo(() => ACL.arrayFilter(restrictions), [restrictions]);
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const type = query.get('type');

  const tabs = useMemo(() => {
    const isPayments = type === 'payments';

    return [
      {
        id: 'configurations.sidebar.info',
        defaultMessage: 'General Info',
        to: addQueryToPath(
          generatePath(ROUTES_PATH.COMPANY_SETTINGS_GENERAL_MAIN.absolute, { companyId }),
          type ? { type } : null,
        ),
        disabled: false,
        tabValue: 'general',
        available: ACLFilterFN(ROUTES_PATH.COMPANY_SETTINGS_GENERAL_MAIN),
      },
      {
        id: 'configurations.sidebar.accountsDetails',
        defaultMessage: 'Accounts Details',
        to: addQueryToPath(
          generatePath(ROUTES_PATH.COMPANY_SETTINGS_ACCOUNTS.absolute, { companyId }),
          type ? { type } : null,
        ),
        disabled: false,
        tabValue: 'accounts',
        available: ACLFilterFN(ROUTES_PATH.COMPANY_SETTINGS_ACCOUNTS) && isPayments,
      },
      {
        id: 'configurations.sidebar.users',
        defaultMessage: 'Users',
        to: generatePath(ROUTES_PATH.COMPANY_SETTINGS_USERS.absolute, { companyId }),
        disabled: false,
        required: checkUsers(users, companyId),
        tip: (
          <FormattedMessage
            id="configurations.sidebar.users.tip"
            defaultMessage="Add a user to your company or assign a Bookkeeper"
          />
        ),
        tabValue: 'users',
        available: ACLFilterFN(ROUTES_PATH.COMPANY_SETTINGS_USERS) && !isPayments,
      },
      {
        id: 'configurations.sidebar.integrations',
        defaultMessage: 'Integrations',
        to: generatePath(ROUTES_PATH.COMPANY_SETTINGS_INTEGRATIONS.absolute, { companyId }),
        disabled: !fin,
        required: fin && checkErps(erps),
        tip: <FormattedMessage id="configurations.sidebar.integrations.tip" defaultMessage="Integrate the ERP" />,
        tabValue: 'integrations',
        available: ACLFilterFN(ROUTES_PATH.COMPANY_SETTINGS_INTEGRATIONS) && !isPayments,
      },
      {
        id: 'configurations.sidebar.applications',
        defaultMessage: 'Applications',
        to: generatePath(ROUTES_PATH.COMPANY_SETTINGS_APPLICATIONS.absolute, { companyId }),
        disabled: false,
        tabValue: 'applications',
        available: ACLFilterFN(ROUTES_PATH.COMPANY_SETTINGS_APPLICATIONS) && !isPayments,
      },
      {
        id: 'configurations.sidebar.supplier_defaults',
        defaultMessage: 'Vendor Preferences',
        to: addQueryToPath(
          generatePath(ROUTES_PATH.COMPANY_SETTINGS_SUPPLIER_DEFAULTS.absolute, { companyId }),
          type ? { type } : null,
        ),
        disabled: !featureSet.supplier_form || !featureSet.fin,
        tabValue: 'supplier_defaults',
        available: ACLFilterFN(ROUTES_PATH.COMPANY_SETTINGS_SUPPLIER_DEFAULTS),
      },
      {
        id: 'configurations.sidebar.companyApprovals',
        defaultMessage: 'Approval Groups',
        to: addQueryToPath(
          generatePath(ROUTES_PATH.COMPANY_SETTINGS_APPROVALS.absolute, { companyId }),
          type ? { type } : null,
        ),
        disabled: !featureSet.approvals,
        tabValue: 'approvals',
        available: ACLFilterFN(ROUTES_PATH.COMPANY_SETTINGS_APPROVALS),
      },
    ].filter((r) => r.available);
  }, [featureSet, companyId, erps, fin, users, ACLFilterFN, type]);

  return (
    <LayoutCompanySettings>
      <CircularProgressWithBackdrop isOpen={loading} />

      <StickySubheader>
        <Box display="flex" alignItems="center">
          <Typography variant="h6">
            {formatMessage({ id: 'configurations.page.title', defaultMessage: 'Settings' })}
          </Typography>
          {subTab === 'main' && (
            <Tooltip t={{ id: 'tooltip.companyDelete', defaultMessage: 'Company Delete' }}>
              <IconButton aria-label="delete" color="error" onClick={toggleIsOpenDelete} sx={{ ml: 'auto' }}>
                <DeleteOutlineOutlinedIcon />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      </StickySubheader>
      <Container maxWidth={false}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={tab} tabs={tabs} />
        </Box>
      </Container>

      <Container maxWidth={false} sx={{ flexGrow: 1, py: 2, display: 'flex', flexDirection: 'column' }}>
        <Outlet />
      </Container>

      {isOpenDelete && <DeleteModal onClose={toggleIsOpenDelete} />}
    </LayoutCompanySettings>
  );
};

export default Configurations;
