// @flow
import { type RecordFactory, Record, List, Set } from 'immutable';
import type { RawUserType, UserType, EnhancedUserType, User } from 'domain/organizationUser/types.js.flow';

// function curry(fn) {
//   const arity = fn.length;

//   return function $curry(...args) {
//     if (args.length < arity) {
//       return $curry.bind(null, ...args);
//     }

//     return fn.call(null, ...args);
//   };
// }

// const map = curry((f, functor) => functor.map(f));
const defaultRecordValues = (role): UserType => ({
  admin: null,
  pic: null,
  id: null,
  email: null,
  first_name: null,
  last_name: null,
  display_name: null,
  display_role: null,
  phone: null,
  role,
  title: '',
  companies_id: new Set(),
  restricted: false,
  limited: false,
  readonly: false,
});

const defaultEnhancedRecordValues: EnhancedUserType = {
  ...defaultRecordValues('accountant'),
  assigned: false,
};

export const UserFactory: RecordFactory<UserType> = new Record(defaultRecordValues('accountant'));
export const ClientFactory: RecordFactory<UserType> = new Record(defaultRecordValues('user'));

// eslint-disable-next-line max-len
export const EnhancedUserFactory: RecordFactory<EnhancedUserType> = new Record(defaultEnhancedRecordValues);

export function singleUserAdapter(rawUser: RawUserType): User {
  const user: UserType | {} = { ...rawUser, companies_id: Set(rawUser.companies_id), pic: rawUser.picture };
  return UserFactory(user);
}

export function UserAdapter(data: Array<RawUserType>): List<User> {
  return List(data.map(singleUserAdapter));
}
