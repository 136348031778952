// @flow
import React from 'react';

import UserProfile, { userProfileTester } from '../customRenderers/UserProfile';
import JsonDrivenFormBase, { type TJsonDrivenFormBase } from '../JsonDrivenFormBase';
import ApprovalTemplate, { approvalTemplateTester } from '../customRenderers/ApprovalTemplate';

const renderers = [
  {
    tester: userProfileTester,
    renderer: UserProfile,
  },
  {
    tester: approvalTemplateTester,
    renderer: ApprovalTemplate,
  },
];

const AirwallexGlobalAccountForm: React$StatelessFunctionalComponent<TJsonDrivenFormBase> = (props) => (
  <JsonDrivenFormBase customRenders={renderers} {...props} />
);

export default AirwallexGlobalAccountForm;
