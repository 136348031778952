// @flow
import React, { useCallback, useMemo, useRef } from 'react';
import { compose } from 'redux';
import { useSelector } from 'react-redux';
import { AgGridReact } from 'ag-grid-react';

import { paymentAccountsGridHeadersListSelector, paymentAccountsSelector } from 'domain/payments/selectors';

import Box from '@mui/material/Box';
import CustomSum from 'pages/company/grid/components/CustomSum';
import UserCell from './components/UserCell';
import StatusCell from './components/StatusCell';
import ContextButton from './components/ContextButton';
import AgGridCustomTooltip from 'pages/company/grid/components/CustomTooltip';

import useAgGridManualConfig from 'hooks/agGrid/useAgGridManualConfig';
import useAgGridDefaultConfig from 'hooks/agGrid/useAgGridDefaultConfig';
import useAgGridAccountsStyles from './hooks/useAgGridAccountsStyles';

import { columnDefsAdapter } from './helpers';
import { addContextMenuColumnRight } from 'pages/company/grid/helpers';

import type { TColumnApi, TGridApi } from 'pages/company/grid/types.js.flow';

const mapStateToProps = (state) => ({
  headers: paymentAccountsGridHeadersListSelector(state),
  accounts: paymentAccountsSelector(state),
});

const AccountsGrid = () => {
  const gridApiRef = useRef();
  const columnApiRef = useRef();
  const agGridDefaultProps = useAgGridDefaultConfig(useAgGridAccountsStyles);
  const { enableRtl, getLocaleText } = useAgGridManualConfig();

  const { headers, accounts } = useSelector(mapStateToProps);

  const columnDefs = useMemo(
    () => ({
      columnDefs: headers ? compose(addContextMenuColumnRight, columnDefsAdapter)(headers) : null,
    }),
    [headers],
  );

  const defaultColDef = useMemo(
    () => ({
      filter: true,
      menuTabs: ['filterMenuTab'],
      suppressMovable: true,
      resizable: true,
      minWidth: 120,
      flex: 1,
    }),
    [],
  );

  const frameworkComponents = {
    customSum: CustomSum,
    userCell: UserCell,
    statusCell: StatusCell,
    context: ContextButton,
    customTooltip: AgGridCustomTooltip,
  };

  const onGridReady = useCallback((p) => {
    const { api, columnApi }: {| api: TGridApi, columnApi: TColumnApi |} = p;
    gridApiRef.current = api;
    columnApiRef.current = columnApi;
  }, []);

  return (
    <Box className="ag-theme-material" flexGrow={1} display="flex" flexDirection="column">
      <AgGridReact
        {...agGridDefaultProps}
        {...columnDefs}
        rowHeight={64}
        getLocaleText={getLocaleText}
        enableRtl={enableRtl}
        getRowId={({ data }) => data.id}
        defaultColDef={defaultColDef}
        enableRangeSelection={false}
        rowData={accounts}
        components={frameworkComponents}
        onGridReady={onGridReady}
        domLayout="autoHeight"
      />
    </Box>
  );
};

export default AccountsGrid;
