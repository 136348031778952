// @flow
import React from 'react';

import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';

import type { AccountType } from 'domain/payments/types.js.flow';

type TProps = {
  data: AccountType,
};

const UserCell: React$StatelessFunctionalComponent<TProps> = ({
  data: {
    user_profile: { userName, avatarUrl },
  },
}) => (
  <Stack direction="row" alignItems="center" gap={1}>
    <Avatar alt={userName} src={avatarUrl} sx={{ width: 24, height: 24 }} />
    <span>{userName}</span>
  </Stack>
);

export default UserCell;
