// @flow
import React, { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { rankWith } from '@jsonforms/core';
import { withJsonFormsOneOfProps } from '@jsonforms/react';

import Dialog from 'components/mui/Dialog';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';

import useToggle from 'hooks/useToggle';

import AddBoxIcon from '@mui/icons-material/AddBox';

type TProps = {
  schema: {
    title: string,
    oneOf: Array<{ const: string, title: string }>,
  },
  path: string,
  data: string,
  handleChange: (path: string, value: string) => void,
};

const ApprovalTemplate: React$StatelessFunctionalComponent<TProps> = ({
  schema: { title, oneOf: options },
  handleChange,
  path,
  data,
}) => {
  const { formatMessage } = useIntl();
  const [isOpenModal, toggleIsOpenModal] = useToggle();

  const noTemplatesOptionText = formatMessage({ id: '111', defaultMessage: 'No Templates' });
  const createNewTemplateBtnText = formatMessage({ id: '111', defaultMessage: 'Create new Template' });

  const handleSelect = useCallback(
    (event, newValue) => {
      if (newValue.title === createNewTemplateBtnText) {
        toggleIsOpenModal();
      } else {
        handleChange(path, newValue.const);
      }
    },
    [createNewTemplateBtnText, toggleIsOpenModal, handleChange, path],
  );

  const renderOption = useCallback(
    (option) => {
      switch (option.title) {
        case createNewTemplateBtnText:
          return (
            <Button variant="text" fullWidth startIcon={<AddBoxIcon />} sx={{ justifyContent: 'flex-start' }}>
              {createNewTemplateBtnText}
            </Button>
          );
        case '':
          return noTemplatesOptionText;
        default:
          return option.title;
      }
    },
    [createNewTemplateBtnText, noTemplatesOptionText],
  );

  const value = useMemo(() => options.find((option) => option.const === data), [options, data]);

  return (
    <>
      <Autocomplete
        value={value}
        onChange={handleSelect}
        disableClearable
        getOptionLabel={(option) => option.title}
        getOptionDisabled={(option) => !option.title}
        options={[...options, { title: createNewTemplateBtnText, const: '__create' }]}
        renderInput={(params) => <TextField {...params} label={title} variant="outlined" />}
        renderOption={(props, option) => (
          <React.Fragment key={option.const}>
            {createNewTemplateBtnText === option.title && <Divider sx={{ height: 1, width: '100%' }} />}
            <li {...props}>{renderOption(option)}</li>
          </React.Fragment>
        )}
      />

      <Dialog open={isOpenModal} onClose={toggleIsOpenModal} title="Create approval template" maxWidth="lg">
        creating approval template component
      </Dialog>
    </>
  );
};

export const approvalTemplateTester = rankWith(
  6,
  (uischema, schema) => uischema.options && uischema.options.type === 'approval_template',
);

export default withJsonFormsOneOfProps(ApprovalTemplate);
