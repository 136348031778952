import * as ACL from 'domain/restriction';

export const PARAMS = {
  COMPANY_ID: 'companyId',
  DOCUMENT_ID: 'documentId',
  DOCUMENT_TYPE: 'documentType',
  CATEGORY1: 'category1',
  CATEGORY2: 'category2',
  CATEGORY3: 'category3',
  RECOVERY_TOKEN: 'recoveryToken',
  COMPANY_TYPE: 'companyType',
  SECTION: 'section',
  ANONYMOUS_TOKEN: 'anonymousToken',
  ACTION: 'action',
  REDIRECT_TO: 'redirectTo',
  PASSWORD_LESS_AUTH_TOKEN: 'passwordLessAuthToken',
  PASSWORD_EXPIRED: 'passwordExpired',
  TAB: 'tab',
};

/**
 * id - string;
 * absolute - absolute path for generatePath fn;
 * relative - relative path used only for router;
 * restriction - bitmask || boolean;
 */

const ROUTES_PATH = {
  // ==============================
  // ======== ORGANIZATION
  ROOT_LAYOUT: {
    id: 'ROOT_LAYOUT',
    restriction: true,
  },
  // ==============================
  // ======== ORGANIZATION
  ORGANIZATIONS: {
    id: 'ORGANIZATIONS',
    absolute: '/organizations',
    relative: '/organizations',
    restriction: ACL.IS_AUTHORIZED,
  },
  // ==============================
  // ======== COMPANIES
  COMPANIES: {
    id: 'COMPANIES',
    absolute: '/',
    relative: '/',
    restriction: ACL.IS_AUTHORIZED,
  },
  // ==============================
  // ======== COMPANY
  COMPANY_WORKSPACE: {
    id: 'COMPANY_WORKSPACE',
    absolute: `/company/standard/:${PARAMS.COMPANY_ID}/:${PARAMS.CATEGORY1}?/:${PARAMS.CATEGORY2}?/:${PARAMS.CATEGORY3}?`,
    relative: `/company/standard/:${PARAMS.COMPANY_ID}/:${PARAMS.CATEGORY1}?/:${PARAMS.CATEGORY2}?/:${PARAMS.CATEGORY3}?`,
    restriction: ACL.IS_AUTHORIZED_AND_NOT_SUPPLIER,
  },
  COMPANY_DEFAULT_CATEGORY: {
    id: 'COMPANY_DEFAULT_CATEGORY',
    absolute: `/company/standard/:${PARAMS.COMPANY_ID}`,
    relative: `/company/standard/:${PARAMS.COMPANY_ID}`,
    restriction: ACL.IS_AUTHORIZED_AND_NOT_SUPPLIER,
  },
  COMPANY_CONFIDENTIAL_WORKSPACE: {
    id: 'COMPANY_CONFIDENTIAL_WORKSPACE',
    absolute: `/company/confidential/:${PARAMS.COMPANY_ID}/:${PARAMS.CATEGORY1}?/:${PARAMS.CATEGORY2}?/:${PARAMS.CATEGORY3}?`,
    relative: `/company/confidential/:${PARAMS.COMPANY_ID}/:${PARAMS.CATEGORY1}?/:${PARAMS.CATEGORY2}?/:${PARAMS.CATEGORY3}?`,
    restriction: ACL.IS_CONFIDENTIAL_GRANTED_USER,
  },
  COMPANY_CONFIDENTIAL_DEFAULT_CATEGORY: {
    id: 'COMPANY_CONFIDENTIAL_DEFAULT_CATEGORY',
    absolute: `/company/confidential/:${PARAMS.COMPANY_ID}`,
    relative: `/company/confidential/:${PARAMS.COMPANY_ID}`,
    restriction: ACL.IS_CONFIDENTIAL_GRANTED_USER,
  },
  COMPANY_PAYMENTS_WORKSPACE: {
    id: 'COMPANY_PAYMENTS_WORKSPACE',
    absolute: `/company/payments/:${PARAMS.COMPANY_ID}/:${PARAMS.CATEGORY1}?/:${PARAMS.CATEGORY2}?/:${PARAMS.CATEGORY3}?`,
    relative: `/company/payments/:${PARAMS.COMPANY_ID}/:${PARAMS.CATEGORY1}?/:${PARAMS.CATEGORY2}?/:${PARAMS.CATEGORY3}?`,
    restriction: ACL.IS_ACCOUNT,
  },
  COMPANY_PAYMENTS_DEFAULT_CATEGORY: {
    id: 'COMPANY_PAYMENTS_DEFAULT_CATEGORY',
    absolute: `/company/payments/:${PARAMS.COMPANY_ID}`,
    relative: `/company/payments/:${PARAMS.COMPANY_ID}`,
    restriction: ACL.IS_ACCOUNT,
  },
  COMPANY_SUPPLIER: {
    id: 'COMPANY_SUPPLIER',
    absolute: `/company/supplier/:${PARAMS.COMPANY_ID}`,
    relative: `/company/supplier/:${PARAMS.COMPANY_ID}`,
    restriction: ACL.IS_SUPPLIER_USER,
  },
  // ==============================
  // ======== DOCUMENT
  DOCUMENT: {
    id: 'DOCUMENT',
    absolute: `/company/:${PARAMS.COMPANY_ID}/document/:${PARAMS.DOCUMENT_ID}`,
    relative: `/company/:${PARAMS.COMPANY_ID}/document/:${PARAMS.DOCUMENT_ID}`,
    restriction: ACL.IS_AUTHORIZED,
  },
  DOCUMENT_MOBILE_APPROVALS: {
    id: 'DOCUMENT_MOBILE_APPROVALS',
    absolute: `/mobile/approvals/:${PARAMS.COMPANY_ID}/:${PARAMS.DOCUMENT_ID}?`,
    relative: `/mobile/approvals/:${PARAMS.COMPANY_ID}/:${PARAMS.DOCUMENT_ID}?`,
    restriction: ACL.IS_AUTHORIZED,
  },
  // ==============================
  // ======== ORGANIZATION_SETTINGS
  ORGANIZATION_SETTINGS: {
    id: 'ORGANIZATION_SETTINGS',
    absolute: '/configurations/*',
    relative: 'configurations/*',
    restriction: ACL.IS_ADMIN,
  },
  ORGANIZATION_SETTINGS_GENERAL: {
    id: 'ORGANIZATION_SETTINGS_GENERAL',
    absolute: '/configurations/general',
    relative: 'general',
    restriction: ACL.IS_ADMIN,
  },
  ORGANIZATION_SETTINGS_GENERAL_MAIN: {
    id: 'ORGANIZATION_SETTINGS_GENERAL_MAIN',
    absolute: '/configurations/general/main',
    relative: 'main',
    restriction: ACL.IS_ADMIN,
  },
  ORGANIZATION_SETTINGS_GENERAL_CONTACT: {
    id: 'ORGANIZATION_SETTINGS_GENERAL_CONTACT',
    absolute: '/configurations/general/contact',
    relative: 'contact',
    restriction: ACL.IS_ADMIN,
  },
  ORGANIZATION_SETTINGS_GENERAL_FINANCIAL: {
    id: 'ORGANIZATION_SETTINGS_GENERAL_FINANCIAL',
    absolute: '/configurations/general/financial',
    relative: 'financial',
    restriction: ACL.IS_ADMIN,
  },
  ORGANIZATION_SETTINGS_GENERAL_FEATURES: {
    id: 'ORGANIZATION_SETTINGS_GENERAL_FEATURES',
    absolute: '/configurations/general/features',
    relative: 'features',
    restriction: ACL.IS_ADMIN,
  },
  ORGANIZATION_SETTINGS_BILLINGS: {
    id: 'ORGANIZATION_SETTINGS_BILLINGS',
    absolute: '/configurations/billings',
    relative: 'billings',
    restriction: ACL.IS_ADMIN,
  },
  ORGANIZATION_SETTINGS_USERS: {
    id: 'ORGANIZATION_SETTINGS_USERS',
    absolute: '/configurations/users',
    relative: 'users',
    restriction: ACL.IS_ADMIN,
  },
  ORGANIZATION_SETTINGS_SECURITY: {
    id: 'ORGANIZATION_SETTINGS_SECURITY',
    absolute: '/configurations/security',
    relative: 'security',
    restriction: ACL.IS_ADMIN,
  },
  ORGANIZATION_SETTINGS_BACKUPS: {
    id: 'ORGANIZATION_SETTINGS_BACKUPS',
    absolute: '/configurations/backups',
    relative: 'backups',
    restriction: ACL.IS_ADMIN,
  },
  ORGANIZATION_SETTINGS_API_KEYS: {
    id: 'ORGANIZATION_SETTINGS_API_KEYS',
    absolute: '/configurations/keys',
    relative: 'keys',
    restriction: ACL.IS_ADMIN,
  },

  // ==============================
  // ======== COMPANY_SETTINGS
  COMPANY_SETTINGS: {
    id: 'COMPANY_SETTINGS',
    absolute: `/company/:${PARAMS.COMPANY_ID}/configurations/*`,
    relative: `/company/:${PARAMS.COMPANY_ID}/configurations/*`,
    restriction: [ACL.IS_ADMIN, ACL.IS_BK_WITHOUT_ADMIN_PERMISSIONS],
  },
  COMPANY_SETTINGS_GENERAL: {
    id: 'COMPANY_SETTINGS_GENERAL',
    absolute: `/company/:${PARAMS.COMPANY_ID}/configurations/general`,
    relative: 'general',
    restriction: ACL.IS_ADMIN,
  },
  COMPANY_SETTINGS_GENERAL_MAIN: {
    id: 'COMPANY_SETTINGS_GENERAL_MAIN',
    absolute: `/company/:${PARAMS.COMPANY_ID}/configurations/general/main`,
    relative: 'main',
    restriction: ACL.IS_ADMIN,
  },
  COMPANY_SETTINGS_GENERAL_FINANCIAL: {
    id: 'COMPANY_SETTINGS_GENERAL_FINANCIAL',
    absolute: `/company/:${PARAMS.COMPANY_ID}/configurations/general/financial`,
    relative: 'financial',
    restriction: ACL.IS_ADMIN,
  },
  COMPANY_SETTINGS_GENERAL_FEATURES: {
    id: 'COMPANY_SETTINGS_GENERAL_FEATURES',
    absolute: `/company/:${PARAMS.COMPANY_ID}/configurations/general/features`,
    relative: 'features',
    restriction: ACL.IS_ADMIN,
  },
  COMPANY_SETTINGS_ACCOUNTS: {
    id: 'COMPANY_SETTINGS_ACCOUNTS',
    absolute: `/company/:${PARAMS.COMPANY_ID}/configurations/accounts`,
    relative: 'accounts',
    restriction: ACL.IS_ADMIN,
  },
  COMPANY_SETTINGS_USERS: {
    id: 'COMPANY_SETTINGS_USERS',
    absolute: `/company/:${PARAMS.COMPANY_ID}/configurations/users`,
    relative: 'users',
    restriction: ACL.IS_ADMIN,
  },
  COMPANY_SETTINGS_INTEGRATIONS: {
    id: 'COMPANY_SETTINGS_INTEGRATIONS',
    absolute: `/company/:${PARAMS.COMPANY_ID}/configurations/integrations`,
    relative: 'integrations',
    restriction: ACL.IS_ADMIN,
  },
  COMPANY_SETTINGS_APPLICATIONS: {
    id: 'COMPANY_SETTINGS_APPLICATIONS',
    absolute: `/company/:${PARAMS.COMPANY_ID}/configurations/applications`,
    relative: 'applications',
    restriction: ACL.IS_ADMIN,
  },
  COMPANY_SETTINGS_SUPPLIER_DEFAULTS: {
    id: 'COMPANY_SETTINGS_SUPPLIER_DEFAULTS',
    absolute: `/company/:${PARAMS.COMPANY_ID}/configurations/supplier_defaults`,
    relative: 'supplier_defaults',
    restriction: [ACL.IS_ADMIN, ACL.IS_BK_WITHOUT_ADMIN_PERMISSIONS],
  },
  COMPANY_SETTINGS_APPROVALS: {
    id: 'COMPANY_SETTINGS_APPROVALS',
    absolute: `/company/:${PARAMS.COMPANY_ID}/configurations/approvals`,
    relative: 'approvals',
    restriction: ACL.IS_ACCOUNT,
  },

  // ==============================
  // ======== PROFILE_SETTINGS
  PROFILE_SETTINGS: {
    id: 'PROFILE_SETTINGS',
    absolute: '/profile/*',
    relative: '/profile/*',
    restriction: ACL.IS_AUTHORIZED,
  },
  PROFILE_SETTINGS_MAIN: {
    id: 'PROFILE_SETTINGS_MAIN',
    absolute: '/profile/main',
    relative: 'main',
    restriction: ACL.IS_AUTHORIZED,
  },
  PROFILE_SETTINGS_NOTIFICATIONS: {
    id: 'PROFILE_SETTINGS_NOTIFICATIONS',
    absolute: '/profile/notifications',
    relative: 'notifications',
    restriction: ACL.IS_AUTHORIZED,
  },
  PROFILE_SETTINGS_PREFERENCES: {
    id: 'PROFILE_SETTINGS_PREFERENCES',
    absolute: '/profile/preferences',
    relative: 'preferences',
    restriction: ACL.IS_AUTHORIZED,
  },
  PROFILE_SETTINGS_ALIASES: {
    id: 'PROFILE_SETTINGS_ALIASES',
    absolute: '/profile/aliases',
    relative: 'aliases',
    restriction: ACL.IS_SUPPLIER_USER,
  },

  // ==============================
  // ======== AUTH
  AUTH_LOGIN: {
    id: 'AUTH_LOGIN',
    absolute: '/login',
    relative: '/login',
    restriction: ACL.IS_NOT_AUTHORIZED,
  },
  AUTH_IDP_LOGIN: {
    id: 'AUTH_IDP_LOGIN',
    absolute: '/idp',
    relative: '/idp',
    restriction: true,
  },
  // PLA - password less authorization
  AUTH_PLA_LOGIN: {
    id: 'AUTH_PLA_LOGIN',
    absolute: `/pla/:${PARAMS.PASSWORD_LESS_AUTH_TOKEN}`,
    relative: `/pla/:${PARAMS.PASSWORD_LESS_AUTH_TOKEN}`,
    restriction: true,
  },
  AUTH_PLA_ACCESS_SUCCESS: {
    id: 'AUTH_PLA_ACCESS_SUCCESS',
    absolute: '/pla-access-success',
    relative: '/pla-access-success',
    restriction: true,
  },
  AUTH_RESET_PASSWORD: {
    id: 'AUTH_RESET_PASSWORD',
    absolute: `/resetpasswd/:${PARAMS.RECOVERY_TOKEN}?/:${PARAMS.PASSWORD_EXPIRED}?`,
    relative: `/resetpasswd/:${PARAMS.RECOVERY_TOKEN}?/:${PARAMS.PASSWORD_EXPIRED}?`,
    restriction: true,
  },
  AUTH_SET_PASSWORD: {
    id: 'AUTH_SET_PASSWORD',
    absolute: `/setpasswd/:${PARAMS.RECOVERY_TOKEN}`,
    relative: `/setpasswd/:${PARAMS.RECOVERY_TOKEN}`,
    restriction: true,
  },
  AUTH_FORGOT_PASSWORD: {
    id: 'AUTH_FORGOT_PASSWORD',
    absolute: '/forgot-password',
    relative: '/forgot-password',
    restriction: true,
  },
  AUTH_LOGOUT: {
    id: 'AUTH_LOGOUT',
    absolute: '/logout',
    relative: '/logout',
    restriction: ACL.IS_AUTHORIZED,
  },

  // ==============================
  // ======== SERVICE
  REDIRECT: {
    id: 'REDIRECT',
    absolute: `/redirect/:${PARAMS.REDIRECT_TO}`,
    relative: `/redirect/:${PARAMS.REDIRECT_TO}`,
    restriction: ACL.IS_AUTHORIZED,
  },
  REQUEST_DOCUMENT: {
    id: 'REQUEST_DOCUMENT',
    absolute: `/request/:${PARAMS.ANONYMOUS_TOKEN}`,
    relative: `/request/:${PARAMS.ANONYMOUS_TOKEN}`,
    restriction: true,
  },
  ACCESS_LIMITED: {
    id: 'ACCESS_LIMITED',
    absolute: '/access_limited',
    relative: '/access_limited',
    restriction: true,
  },
  DOCUMENT_NOT_FOUND: {
    id: 'DOCUMENT_NOT_FOUND',
    absolute: `/company/:${PARAMS.COMPANY_ID}/document_not_found`,
    relative: `/company/:${PARAMS.COMPANY_ID}/document_not_found`,
    restriction: true,
  },
  NOT_SUPPORTED_IPAD: {
    id: 'NOT_SUPPORTED_IPAD',
    absolute: '/ipad_not_supported',
    relative: '/ipad_not_supported',
    restriction: true,
  },
  NOT_SUPPORTED_BROWSER: {
    id: 'NOT_SUPPORTED_BROWSER',
    absolute: '/not_supported_browser',
    relative: '/not_supported_browser',
    restriction: true,
  },
  NOT_SUPPORTED_MOBILE_BROWSER: {
    id: 'NOT_SUPPORTED_MOBILE_BROWSER',
    absolute: `/mobile_browser_not_supported/:${PARAMS.ACTION}`,
    relative: `/mobile_browser_not_supported/:${PARAMS.ACTION}`,
    restriction: true,
  },
  MAINTENANCE: {
    id: 'MAINTENANCE',
    absolute: '/maintenance',
    relative: '/maintenance',
    restriction: true,
  },
  EXPIRED: {
    id: 'EXPIRED',
    absolute: '/expired',
    relative: '/expired',
    restriction: ACL.IS_EXPIRED,
  },
  PAGE404: {
    id: 'PAGE404',
    absolute: '*',
    relative: '*',
    restriction: true,
  },
};

export default ROUTES_PATH;
