// @flow
import { Record, List, type RecordOf, type RecordFactory } from 'immutable';

import type {
  PaymentProvidersListType,
  PaymentProviderType,
  AccountUserProfileDataType,
  AccountType,
  GridAccountsRawHeaderItemType,
} from './types.js.flow';

export const AccountStatuses = {
  active: 'ACTIVE',
  suspended: 'SUSPENDED',
};

export const paymentProviderFactory = (data: PaymentProviderType): RecordOf<PaymentProviderType> => {
  const record: RecordFactory<PaymentProviderType> = new Record({
    payment_provider: '',
    status: '',
  });
  return record(data);
};
export const paymentProvidersList = (list: $ReadOnlyArray<PaymentProviderType> = []): PaymentProvidersListType =>
  List(list).map((item) => paymentProviderFactory(item));

export const UserProfileFactory: RecordFactory<AccountUserProfileDataType> = new Record({
  avatarUrl: '',
  userName: '',
});

export const AccountFactory: RecordFactory<AccountType> = new Record({
  id: '',
  payment_provider: '',
  country: '',
  account_name: '',
  erp_gl_account: '',
  user_profile: UserProfileFactory(),
  status: '',
});

const GridAccountsHeaderItemFactory: RecordFactory<GridAccountsRawHeaderItemType> = new Record({
  field: '',
  headerName: '',
  type: 'string',
  hide: false,
  operator: undefined,
  tooltipComponent: 'customTooltip',
});

export const gridAccountsHeadersListAdapter = (rawList: GridAccountsRawHeaderItemType[]) =>
  List(rawList.map((item) => GridAccountsHeaderItemFactory(item)));

export const gridAccountsListAdapter = (rawList: AccountType[]) => List(rawList.map((item) => AccountFactory(item)));
