// @flow
import React, { useState } from 'react';
import { type AxiosXHRConfig } from 'axios';
import {
  isDomainSSOOnly,
  checkForTokenInactivityTimeout,
  getCognitoToken,
  nullifyTokenRefreshTime,
} from 'amplify/helpers';
import { storage } from 'lib/storage';
import { STUB_TOKEN } from 'domain/constants';
import type { AuthData } from './types';
import { updateAuthRequestData } from './helpers';
import { signOutAction } from 'domain/env';

type Params = {
  [key: string]: string,
};

export class TokenKeeper {
  _store: any;

  _currentParams: Params = {};

  injectStore = (store) => {
    this._store = store;
  };

  injectCurrentParams = (params: Params) => {
    this._currentParams = params;
  };

  getClientToken = async () => {
    try {
      // throws exception in case inactivity timeout reached causing logout
      checkForTokenInactivityTimeout();

      // returns active or refreshes token so that we are always
      // dealing with fresh token
      const token = await getCognitoToken();
      return token;
    } catch (e) {
      nullifyTokenRefreshTime();
      // No current user - in this case signOut does nothing, must trigger signOutSuccess
      if (e === 'No current user') {
        // this is not proper solution
        // idea is to remove role so that after user is redirected to login and
        // logs in, app doesnt consider user authorized due to role being present
        // in LS
        storage().role().remove();
        storage().token().remove();
        this._store.dispatch({ type: signOutAction.success });
      } else {
        // there are 2 reasons for exception to be thrown:
        //
        // 1. getCognitoToken function didnt manage to refresh token due to
        //    number of reasons. In most cases it indicates refresh token
        //    expiration and user has no way to continue current auth session
        // 2. checkForTokenInactivityTimeout throws exception in case token
        //    wasn't refreshed for certain period that indicates user wasnt
        //    interacting with app and we force logout by throwing this particular
        //    exception

        // this is required as getClientToken might be consequently invoked
        // and set new refresh token timestamp after we nulified it inside
        // checkForTokenInactivityTimeout()

        this._store.dispatch({ type: signOutAction.type });
      }
    }
  };

  isDokkaLogin = () => !!this.getDokkaToken();

  // this is deprecated and is never expected to be invoked. Remove after first successful Cognito release
  getDokkaToken = () => {
    const storeToken = this._store.getState().env.get('dokkaToken') || storage().token().get();
    return storeToken && storeToken !== STUB_TOKEN ? storeToken : '';
  };

  getToken = async () => {
    const isSSOOnly = isDomainSSOOnly();
    // if domain is not sso domain, getDokkaToken will return '' and we fall back to getClientToken  
    const token = isSSOOnly ? await this.getClientToken() : this.getDokkaToken() || (await this.getClientToken());
    return token;
  };

  getLanguage = () => this._store.getState().env.get('locale');

  getCompany = () => this._currentParams.companyId || null;

  getRequestData = async (): AuthData | null => {
    const token = await this.getToken();
    const lang = this.getLanguage();
    const company = this.getCompany();
    return {
      token,
      lang,
      company,
    };
  };

  getPaymentRequestData = async (): AuthData | null => {
    const requestData = await this.getRequestData()
    return {
      ...requestData,
      payment_provider: 'airwallex',
    }
  }

  updateRequestData = async (requestData: AxiosXHRConfig): AxiosXHRConfig => {
    const requestUpdateData = await this.getRequestData();
    return requestUpdateData ? updateAuthRequestData(requestData, requestUpdateData) : requestData;
  };

  updatePaymentRequestData = async (requestData: AxiosXHRConfig): AxiosXHRConfig => {
    const requestUpdateData = await this.getPaymentRequestData();
    return requestUpdateData ? updateAuthRequestData(requestData, requestUpdateData) : requestData;
  };
}

const keeper = new TokenKeeper();
export const useApiToken = () => {
  const [token, setToken] = useState(null);
  keeper.getToken().then((res) => {
    if (token !== res) {
      setToken(res);
    }
  });
  return token || null;
};

export const withApiToken = (Component) => (props: any) => {
  const apiToken = useApiToken();

  return <Component {...props} apiToken={apiToken} />;
};

export default keeper;
