// @flow
import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import $RefParser from '@apidevtools/json-schema-ref-parser';

import { createPaymentAccountAction, getPaymentAccountCreationFormSchemaAction } from 'domain/payments/actions';

import Dialog from 'components/mui/Dialog';
import EmptyScreen from './components/EmptyScreen';
import AirwallexGlobalAccountForm from 'components/Form/JsonDrivenForms/AirwallexGlobalAccountForm';
import BoxMui from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';

import useToggle from 'hooks/useToggle';
import AccountsGrid from './components/AccountsGrid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { isConnectedPaymentProviderSelector, paymentAccountsSelector } from 'domain/payments';

const hideErrors = 'ValidateAndHide';
const showErrors = 'ValidateAndShow';

const Box = styled(BoxMui, { label: 'DialogBox' })(() => ({
  '.MuiGrid-root': {
    '.MuiGrid-root.MuiGrid-container': {
      marginTop: 0,
    },
  },
}));

const mapStateToProps = (state) => ({
  isConnectedPaymentProvider: isConnectedPaymentProviderSelector(state),
  accounts: paymentAccountsSelector(state),
});

const AccountsList = () => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const [validationMode, setValidationMode] = useState(hideErrors);
  const [schemata, setSchemata] = useState({});
  const [hasErrors, setErrors] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isOpenModal, toggleIsOpenModal] = useToggle();

  const { isConnectedPaymentProvider, accounts } = useSelector(mapStateToProps);

  const { schema, uischema, data } = schemata;

  const onFormDataChanged = useCallback(({ data, errors }) => {
    setErrors(errors.length > 0);
    setValidationMode(hideErrors); // clear validation errors once user interracted with form
    setSchemata({ ...schemata, data });
  });

  const onCreateAccount = useCallback(() => {
    setValidationMode(showErrors);
    if (!hasErrors) {
      setIsSubmitting(true);
      new Promise((resolve, reject) => {
        dispatch(createPaymentAccountAction({ data, resolve, reject }));
      })
        .then(() => {
          toggleIsOpenModal();
        })
        .catch(() => {
          // consider moving setValidationMode(showErrors) here
          setIsSubmitting(false);
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    }
  }, [data, toggleIsOpenModal, hasErrors, dispatch]);

  useEffect(() => {
    if (isOpenModal) {
      setIsloading(true);
      new Promise((resolve, reject) => {
        dispatch(getPaymentAccountCreationFormSchemaAction({ resolve, reject }));
      })
        .then(async (data) => {
          const parsedSchema = await $RefParser.dereference(data.schema);
          setSchemata({ ...data, schema: parsedSchema });
        })
        .finally(() => setIsloading(false));
    }
  }, [isOpenModal, dispatch]);

  return (
    <Stack component={Paper} variant="outlined" p={2} gap={4}>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="subtitle1" fontWeight={500}>
          {formatMessage({ id: 'configurations.company.accounts.accountsList.title', defaultMessage: 'Accounts List' })}
        </Typography>
        {!!accounts.length && (
          <Button
            startIcon={<AddOutlinedIcon />}
            variant="outlined"
            onClick={toggleIsOpenModal}
            disabled={!isConnectedPaymentProvider}
          >
            {formatMessage({
              id: 'configurations.company.accounts.button.addNewAccount',
              defaultMessage: 'New account',
            })}
          </Button>
        )}
      </Stack>
      {accounts.length ? (
        <AccountsGrid />
      ) : (
        <EmptyScreen onOpenModal={toggleIsOpenModal} isConnectedPaymentProvider={isConnectedPaymentProvider} />
      )}
      <Dialog
        onOk={onCreateAccount}
        open={isOpenModal}
        onClose={toggleIsOpenModal}
        okBtnProps={{ disabled: isSubmitting }}
        title={formatMessage({
          id: 'configurations.company.accounts.addNewAccountModal.title',
          defaultMessage: 'Add new payment account',
        })}
        okText={formatMessage({
          id: 'button.create',
          defaultMessage: 'Create',
        })}
        maxWidth="lg"
      >
        {isLoading ? (
          <Stack height="100%" justifyContent="center" alignItems="center">
            <CircularProgress />
          </Stack>
        ) : (
          <Box>
            <AirwallexGlobalAccountForm
              schema={schema}
              uischema={uischema}
              data={data}
              onFormDataChanged={onFormDataChanged}
              validationMode={validationMode}
            />
          </Box>
        )}
      </Dialog>
    </Stack>
  );
};

export default AccountsList;
