// @flow
import React from 'react';

import CheckBoxWithLink, { checkBoxWithLinkTester } from '../customRenderers/TermsAndAgreements';
import JsonDrivenFormBase, { type TJsonDrivenFormBase } from '../JsonDrivenFormBase';

const renderers = [
  {
    tester: checkBoxWithLinkTester,
    renderer: CheckBoxWithLink,
  },
];

const AirwallexConnectedAccountForm: React$StatelessFunctionalComponent<TJsonDrivenFormBase> = (props) => (
  <JsonDrivenFormBase customRenders={renderers} {...props} />
);

export default AirwallexConnectedAccountForm;
