// @flow
import React from 'react';
import { useIntl } from 'react-intl';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import AddOutlinedIcon from '@mui/icons-material/AddOutlined';

type TProps = {
  onOpenModal: () => void,
  isConnectedPaymentProvider: boolean,
};

const EmptyScreen: React$StatelessFunctionalComponent<TProps> = ({
  onOpenModal = () => {},
  isConnectedPaymentProvider,
}) => {
  const { formatMessage } = useIntl();

  return (
    <Stack width={300} textAlign="center" alignItems="center" spacing={2} m="0 auto !important">
      <Typography variant="h6" color="primary">
        {formatMessage({ id: 'configurations.company.accounts.empty.title', defaultMessage: 'No Pay Accounts' })}
      </Typography>
      <Typography variant="body1">
        {formatMessage({
          id: 'configurations.company.accounts.empty.description',
          defaultMessage: 'No payment accounts have yet been established for this company',
        })}
      </Typography>
      <Button startIcon={<AddOutlinedIcon />} onClick={onOpenModal} disabled={!isConnectedPaymentProvider}>
        {formatMessage({
          id: 'configurations.company.accounts.button.addNewAccount',
          defaultMessage: 'New account',
        })}
      </Button>
    </Stack>
  );
};

export default EmptyScreen;
