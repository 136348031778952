// @flow
import merge from 'lodash/fp/merge';

import type { TColumnDefs } from 'pages/company/grid/types';

const statusColDef = {
  cellRenderer: 'statusCell',
  filter: 'agSetColumnFilter',
};

const userColDef = {
  cellRenderer: 'userCell',
  valueFormatter: ({ value }) => value.userName,
  tooltipValueGetter: ({ valueFormatted }) => valueFormatted,
};

const stringColDef = {
  filter: 'agTextColumnFilter',
  filterParams: { buttons: ['reset'] },
  valueGetter: (params) => params.data[params.colDef.field],
  valueFormatter: ({ value }) => value,
  tooltipValueGetter: ({ valueFormatted }) => valueFormatted,
};

export const columnDefsAdapter = (headers: TColumnDefs[]) =>
  headers.map((header) => {
    switch (header.type) {
      case 'user':
        return merge(userColDef, header);
      case 'string':
        return merge(stringColDef, header);
      case 'status':
        return merge(statusColDef, header);
      default:
        return header;
    }
  });
