// @flow
import React from 'react';
import { withJsonFormsControlProps } from '@jsonforms/react';

import TextField from '@mui/material/TextField';
import Avatar from '@mui/material/Avatar';
import InputAdornment from '@mui/material/InputAdornment';

type Props = {
  data: {
    avatarUrl: string,
    userName: string,
  },
  label: string,
};

const UserProfile: React$StatelessFunctionalComponent<Props> = ({ data: { avatarUrl, userName }, label }) => (
  <TextField
    fullWidth
    label={label}
    value={userName}
    disabled
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <Avatar alt={userName} src={avatarUrl} sx={{ width: 24, height: 24 }} />
        </InputAdornment>
      ),
    }}
    variant="outlined"
  />
);

export const userProfileTester = (uischema, schema) => (schema.properties && schema.properties.userName ? 3 : -1);

export default withJsonFormsControlProps(UserProfile);
