/* @flow */
import { Record, type RecordOf, List } from 'immutable';
import * as A from './actions';

import type { PaymentsType } from './types.js.flow';

const initialState: RecordOf<PaymentsType> = new Record({
  isLoading: false,
  providers: new List(),
  accounts: {
    list: new List(),
    gridHeadersList: new List(),
  },
});

export const reducer = {
  payments(state = initialState(), action: any) {
    switch (action.type) {
      case A.getProvidersAction.request:
        return state.set('isLoading', true);

      case A.getProvidersAction.success:
        return state.set('providers', action.payload).set('isLoading', false);

      case A.getProvidersAction.failure:
        return state.set(['isLoading'], false);

      case A.getPaymentAccountsAction.success:
        return state
          .setIn(['accounts', 'gridHeadersList'], action.payload.gridHeadersList)
          .setIn(['accounts', 'list'], action.payload.list);

      case A.updateProviderStatusAction.type:
        return state.updateIn(['providers', 0], (currentProvider) => {
          return currentProvider.set('status', action.payload);
        });

      default:
        return state;
    }
  },
};
