// @flow
import React, { useCallback, useContext, useMemo } from 'react';
import { useIntl } from 'react-intl';

import CascadingMenuContext from './CascadingMenuContext';
import HoverMenu from 'material-ui-popup-state/HoverMenu';
import {
  bindFocus,
  bindHover,
  bindMenu,
  usePopupState,
  type PopupState as TPopupState,
} from 'material-ui-popup-state/hooks';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Tooltip from 'components/mui/Tooltip';
import { useTheme } from '@mui/material';

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ListItemIcon from '@mui/material/ListItemIcon';

type TOption = {
  disabled?: boolean,
  isHidden?: boolean,
  color?: string,
  divided?: boolean,
  tooltipMessage?: { id: string, defaultMessage: string },
  id: string,
  title: { id: string, defaultMessage: string },
  onClick: () => void,
  submenu?: Array<TOption>,
  Icon: React$Node,
};

type TMoreMenuItem = {
  option: Array<TOption>,
};

export const MoreMenuItem: React$StatelessFunctionalComponent<TMoreMenuItem> = ({ option }) => {
  const { rootPopupState, parentPopupState } = useContext(CascadingMenuContext);
  const { direction } = useTheme();
  const { formatMessage } = useIntl();
  const isRtl = direction === 'rtl';
  const { divided, disabled, color, title, submenu, onClick, id, tooltipMessage, Icon } = option;

  const popupState = usePopupState({
    id,
    variant: 'popover',
    parentPopupState,
  });

  const handleClick = useCallback(
    (e) => {
      if (disabled) return;
      rootPopupState.close(e);
      if (typeof onClick === 'function') onClick();
    },
    [rootPopupState, onClick, disabled],
  );

  const boundProps = submenu
    ? {
        ...bindHover(popupState),
        ...bindFocus(popupState),
      }
    : {};

  return (
    <>
      {divided && <Divider component="li" />}
      <MenuItem disabled={disabled} onClick={handleClick} {...boundProps} style={{ pointerEvents: 'auto' }}>
        <ListItemIcon sx={{ minWidth: 32 }}>
          <Icon color={color || 'inherit'} />
        </ListItemIcon>
        <Typography variant="body2" flexGrow={1} color={color ? `${color}.main` : 'inherit'}>
          {formatMessage(title)}
        </Typography>
        {disabled && (
          <Tooltip t={formatMessage(tooltipMessage)}>
            <InfoOutlinedIcon fontSize="small" sx={{ ml: 1 }} />
          </Tooltip>
        )}
        {submenu && (
          <>
            {isRtl ? <KeyboardArrowLeftIcon sx={{ mr: -1 }} /> : <KeyboardArrowRightIcon sx={{ mr: -1 }} />}
            <CascadingHoverMenu popupState={popupState} options={submenu} isRtl={isRtl} />
          </>
        )}
      </MenuItem>
    </>
  );
};

type TCascadingHoverMenu = {
  popupState: TPopupState,
  options: Array<TOption>,
  isRtl: boolean,
  ...TOption,
};

export const CascadingHoverMenu: React$StatelessFunctionalComponent<TCascadingHoverMenu> = ({
  popupState,
  options,
  isRtl,
  ...props
}) => {
  const { rootPopupState } = useContext(CascadingMenuContext);
  const context = useMemo(
    () => ({
      rootPopupState: rootPopupState || popupState,
      parentPopupState: popupState,
    }),
    [rootPopupState, popupState],
  );

  const list = options.filter((option) => !option.isHidden);

  return (
    <CascadingMenuContext.Provider value={context}>
      <HoverMenu
        anchorOrigin={{ vertical: 'top', horizontal: isRtl ? 'left' : 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: isRtl ? 'right' : 'left' }}
        PaperProps={{ sx: { ...(isRtl ? { mr: -0.5 } : { ml: 0.5 }) } }}
        {...props}
        {...bindMenu(popupState)}
      >
        {list.map((option) => (
          <MoreMenuItem option={option} key={option.id} />
        ))}
      </HoverMenu>
    </CascadingMenuContext.Provider>
  );
};
