// @flow
import React, { useState, useCallback, useEffect } from 'react';
import { createElement, init } from '@airwallex/components-sdk';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import toast from 'components/Toast';

import {
  generateCodeChallengeFromVerifier,
  generateCodeVerifier,
  getAirwallexEmbededComponentProps,
  type TAirwallexEmbededComponentProps,
} from './utils';

import Api from 'domain/api';

const AirwallexEmbededComponent: React$StatelessFunctionalComponent<TAirwallexEmbededComponentProps> = ({
  component,
  onSuccess,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const { container, scope } = getAirwallexEmbededComponentProps(component);

  const handleError = useCallback(() => {
    toast.error('Oops, something is wrong');
  }, []);

  const handleReady = useCallback(() => {
    setIsLoading(false);
  }, []);

  const handleSuccess = useCallback((event) => {
    onSuccess?.(event);
  }, [onSuccess]);

  useEffect(() => {
    let element;

    const initializeSDK = async () => {
      const codeVerifier = generateCodeVerifier();
      const codeChallenge = await generateCodeChallengeFromVerifier(codeVerifier);
      const getAuthCode = async () =>
        await Api.getAirwallexAuthCode({ data: { code_challenge: codeChallenge, ...(scope ? { scope } : {}) } });
      const {
        data: { authorization_code: authCode },
      } = await getAuthCode();

      try {
        // initialize AirwallexOnboarding instance on window
        await init({
          authCode,
          codeVerifier,
          env: process.env.API_ENV,
          clientId: process.env.CLIENT_ID,
        });

        const mountElement = async () => {
          // create onboarding element
          element = await createElement(component, {
            hideHeader: true,
            hideNav: true,
          });

          // append to DOM
          await element?.mount(container);

          // subscribe element events
          element?.on('ready', (event: any) => {
            handleReady(event);
          });

          element?.on('error', (event: any) => {
            handleError(event);
          });

          element?.on('success', (event: any) => {
            handleSuccess(event);
          });
        };
        await mountElement();
      } catch (e) {
        console.error(e);
        toast.error('SDK failed to initialize!');
      }
      setIsLoading(false);
    };

    initializeSDK();

    return () => element?.destroy();
  }, [component, container, handleError, handleReady, scope]);

  return (
    <Box id={container} height="80vh">
      {isLoading && (
        <Stack height="100%" justifyContent="center" alignItems="center">
          <CircularProgress size={60} />
        </Stack>
      )}
    </Box>
  );
};

export default AirwallexEmbededComponent;
