import React from 'react';
import { FormattedMessage } from 'react-intl';

import MuiChip from '@mui/material/Chip';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import ArrowRightIcon from 'pages/components/AccordionArrowRightIcon';
import MuiButton from '@mui/material/Button';
import MuiLoadingButton from '@mui/lab/LoadingButton';

import { Statuses } from './helpers';

import { styled } from '@mui/material/styles';
import { deepPurple } from '@mui/material/colors';

export const Chip = styled(MuiChip)(() => ({
  borderRadius: 4,
}));

export const createStatuses = {
  [Statuses.active]: (
    <Chip
      color="success"
      label={<FormattedMessage id="settings.connections.connected" defaultMessage="Connected" />}
      size="small"
    />
  ),
  [Statuses.submitted]: <Chip color="primary" label="KYC Submitted" size="small" />,
  [Statuses.actionRequired]: <Chip sx={{ bgcolor: deepPurple[500], color: 'common.white' }} label="RFI" size="small" />,
  [Statuses.suspended]: <Chip color="warning" label="Suspended" size="small" />,
};

export const Accordion = styled(MuiAccordion)(({ theme }) => ({
  marginBottom: 1.5,
  marginTop: '12px !important',
  border: `1px solid ${theme.palette.grey[200]}`,
  borderRadius: theme.shape.borderRadius * 2,
  boxShadow: 'none',

  '&.MuiPaper-root.MuiAccordion-root::before': {
    display: 'none',
  },
}));

export const AccordionSummary = styled((props) => (
  <MuiAccordionSummary elevation={0} expandIcon={<ArrowRightIcon />} {...props} />
))(({ theme }) => ({
  flexDirection: 'row-reverse',
  backgroundColor: theme.palette.grey[50],
  '& .MuiAccordionSummary-content': {
    margin: 0,
    height: 64,
  },
  '& .MuiAccordionSummary-content.Mui-expanded': {
    margin: 0,
  },
}));

export const Button = ({ onClick, ...rest }) => (
  <MuiButton
    {...rest}
    onClick={(...args) => {
      args[0].stopPropagation();
      if (typeof onClick === 'function') onClick(...args);
    }}
  />
);
export const LoadingButton = ({ onClick, ...rest }) => (
  <MuiLoadingButton
    {...rest}
    onClick={(...args) => {
      args[0].stopPropagation();
      if (typeof onClick === 'function') onClick(...args);
    }}
  />
);
