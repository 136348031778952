// @flow
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import TransitEnterexitRoundedIcon from '@mui/icons-material/TransitEnterexitRounded';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';

export const actionsList = [
  {
    id: 'configurations.company.accounts.grid.contextMenu.edit',
    defaultMessage: 'Edit details',
    onClick: () => null,
    Icon: EditOutlinedIcon,
  },
  {
    id: 'configurations.company.accounts.grid.contextMenu.viewTransactions',
    defaultMessage: 'View transactions',
    onClick: () => null,
    Icon: TransitEnterexitRoundedIcon,
  },
  {
    id: 'configurations.company.accounts.grid.contextMenu.suspend',
    defaultMessage: 'Suspend account',
    onClick: () => null,
    Icon: RemoveCircleOutlineOutlinedIcon,
  },
  {
    id: 'configurations.company.accounts.grid.contextMenu.activate',
    defaultMessage: 'Activate account',
    onClick: () => null,
    Icon: CheckCircleOutlinedIcon,
  },
];
