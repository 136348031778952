// @flow
import React from 'react';
import { JsonForms } from '@jsonforms/react';
import { materialCells, materialRenderers } from '@jsonforms/material-renderers';

export type TJsonDrivenFormBase = {|
  onFormDataChanged: (data: {}) => void,
  schema: {},
  uischema: {},
  data: {},
  validationMode: 'ValidateAndHide' | 'ValidateAndShow',
|};

type Props = {
  ...TJsonDrivenFormBase,
  customRenders?: Array<{
    tester: (uischema: any, schema: any) => number,
    renderer: any,
  }>,
};

const JsonDrivenFormBase: React$StatelessFunctionalComponent<Props> = ({
  schema,
  uischema,
  data,
  onFormDataChanged,
  validationMode,
  customRenders = [],
}) => {
  const dataAvailable = typeof schema !== 'undefined' && typeof uischema !== 'undefined';

  return (
    <>
      {dataAvailable && (
        <JsonForms
          schema={schema}
          uischema={uischema}
          data={data}
          renderers={[...materialRenderers, ...customRenders]}
          cells={materialCells}
          validationMode={validationMode}
          onChange={onFormDataChanged}
        />
      )}
    </>
  );
};

export default JsonDrivenFormBase;
