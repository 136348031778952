// @flow
import React from 'react';

import Chip from '@mui/material/Chip';

import { AccountStatuses } from 'domain/payments/helpers';

import type { AccountType } from 'domain/payments/types.js.flow';

type TProps = {
  data: AccountType,
};

const getColor = (status) => {
  switch (status) {
    case AccountStatuses.active:
      return 'success';
    case AccountStatuses.suspended:
      return 'warning';
    default:
      return 'primary';
  }
};

const StatusCell: React$StatelessFunctionalComponent<TProps> = ({ data: { status } }) => (
  <Chip color={getColor(status)} label={status} />
);

export default StatusCell;
