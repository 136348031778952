// @flow
import { createContext } from 'react';
import type { PopupState } from 'material-ui-popup-state/hooks';

export type TCascadingMenuContext = {
  rootPopupState: PopupState | null,
  parentPopupState: PopupState | null,
};

const CascadingMenuContext: TCascadingMenuContext = createContext({
  parentPopupState: null,
  rootPopupState: null,
});

export default CascadingMenuContext;
