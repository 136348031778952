// @flow
import React, { useState, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import AccordionDetails from '@mui/material/AccordionDetails';
import Dialog from 'components/mui/Dialog';

import usePaymentProvidersConfig from './hooks/usePaymentProvidersConfig';
import useToggle from 'hooks/useToggle';
import { Statuses } from './helpers';
import { AirwallexEmbededComponentComponents } from './components/AirwallexEmbededComponent/utils';
import { emulateConnectedAccountStatusAction, updateProviderStatusAction } from 'domain/payments/actions';

import { Accordion, AccordionSummary, createStatuses, Button } from './styled';

type TProviders = {
  onConnect: () => void,
};

const Providers: React$StatelessFunctionalComponent<TProviders> = ({ onConnect }) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const { paymentProvidersConfig } = usePaymentProvidersConfig();
  const [openedSection, setOpenedSection] = useState(null);
  const [paymentProvider, setPaymentProvider] = useState(null);
  const [isOpenKYCModal, toggleIsOpenKYCModal] = useToggle();

  const handleOpenKYCModal = useCallback(
    (props) => {
      setPaymentProvider(props);
      toggleIsOpenKYCModal();
    },
    [toggleIsOpenKYCModal],
  );

  const handleCloseKYCModal = useCallback(() => {
    setPaymentProvider(null);
    toggleIsOpenKYCModal();
  }, [toggleIsOpenKYCModal]);

  const onSuspendClick = useCallback(() => {}, []);
  const onDisconnectClick = useCallback(() => {}, []);
  const onReactivateClick = useCallback(() => {}, []);
  const onEmulateActivationClick = useCallback((provider) => {
    dispatch(emulateConnectedAccountStatusAction(provider));
  }, []);
  const optimisticConnectedAccountUpdate = useCallback(() => {
    dispatch(updateProviderStatusAction(Statuses.submitted));
  })

  const renderButton = useCallback(
    (props) => {
      switch (props.status) {
        case Statuses.disconnected:
          return (
            <Button onClick={onConnect}>
              {formatMessage({ id: 'settings.connections.table.connect', defaultMessage: 'Connect' })}
            </Button>
          );
        case Statuses.created:
          return (
            <Button onClick={() => handleOpenKYCModal(props)}>
              {formatMessage({ id: 'configurations.company.accounts.accountsList.btn.kyc', defaultMessage: 'KYC' })}
            </Button>
          );
        case Statuses.actionRequired:
          return (
            <Button onClick={() => handleOpenKYCModal(props)}>
              {formatMessage({ id: 'configurations.company.accounts.accountsList.btn.rfi', defaultMessage: 'RFI' })}
            </Button>
          );
        case Statuses.submitted:
          return (
            <Button onClick={() => onEmulateActivationClick(props.name)}>
              {formatMessage({
                id: 'configurations.company.accounts.accountsList.btn.activate',
                defaultMessage: 'Activate',
              })}
            </Button>
          );
        case Statuses.active:
          return (
            <>
              <Button onClick={() => onSuspendClick(props)}>
                {formatMessage({
                  id: 'configurations.company.accounts.accountsList.btn.suspend',
                  defaultMessage: 'Suspend',
                })}
              </Button>
            </>
          );
        case Statuses.suspended:
          return (
            <Stack direction='row' spacing={1}>
              <Button onClick={() => onReactivateClick(props.title)}>
                {formatMessage({
                  id: 'configurations.company.accounts.accountsList.btn.reactivate',
                  defaultMessage: 'Reactivate',
                })}
              </Button>
              <Button variant='text' color='error' onClick={() => onDisconnectClick(props.title)}>
                {formatMessage({ id: 'settings.connections.disconnect', defaultMessage: 'Disconnect' })}
              </Button>
            </Stack>
          );
        default: {
          return null;
        }
      }
    },
    [onConnect, formatMessage, handleOpenKYCModal, onReactivateClick, onDisconnectClick, onSuspendClick],
  );

  const handleSectionClick = (section) => () => {
    setOpenedSection(openedSection === section ? null : section);
  };

  const renderKYCComponent = useCallback(
    (props) => {
      if (paymentProvider) {
        const { KYCComponent } = paymentProvider;

        return (
          <KYCComponent
            component={
              props.status === Statuses.actionRequired
                ? AirwallexEmbededComponentComponents.kycRfi
                : AirwallexEmbededComponentComponents.kyc
            }
            onSuccess={optimisticConnectedAccountUpdate}
          />
        );
      }

      return null;
    },
    [paymentProvider],
  );

  return (
    <Stack component={Paper} variant='outlined' p={2}>
      <Typography variant='subtitle1' fontWeight={500}>
        {formatMessage({
          id: 'configurations.company.accounts.paymentProviders.title',
          defaultMessage: 'Payment Providers',
        })}
      </Typography>
      {paymentProvidersConfig.map(({ title, status, icon, ...rest }) => (
        <Accordion key={title} expanded={title === openedSection} onChange={handleSectionClick(title)}>
          <AccordionSummary>
            <Stack direction='row' justifyContent='space-between' alignItems='center' flex='1' flexWrap='no-wrap'>
              <Stack display='flex' direction='row' spacing={1} alignItems='center'>
                <Stack
                  justifyContent='center'
                  alignItems='center'
                  sx={(theme) => ({
                    padding: theme.spacing(0.5, 1),
                    border: 1,
                    borderColor: theme.palette.grey[200],
                    backgroundColor: theme.palette.common.white,
                    borderRadius: 2,
                    minHeight: 34,
                  })}
                >
                  <img width={40} height='auto' src={icon} alt='icon' />
                </Stack>
                <Typography>{[title]}</Typography>
                {createStatuses[status]}
              </Stack>
              <Box>{renderButton({ status, title, ...rest })}</Box>
            </Stack>
          </AccordionSummary>
          <AccordionDetails sx={{ p: 2, pl: 5, pt: 0 }}>DETAILS SECTION</AccordionDetails>
        </Accordion>
      ))}
      <Dialog
        open={isOpenKYCModal}
        onClose={handleCloseKYCModal}
        title='Pass KYC'
        maxWidth='lg'
        withActions={false}
        withCloseBtn
      >
        {renderKYCComponent(paymentProvider)}
      </Dialog>
    </Stack>
  );
};

export default Providers;
