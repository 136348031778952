// @flow
import React, { useMemo } from 'react';
import { usePopupState, bindTrigger, bindMenu } from 'material-ui-popup-state/hooks';

import IconButton from '@mui/material/IconButton';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Menu from '@mui/material/Menu';
import Stack from '@mui/material/Stack';
import CascadingMenuContext from './components/CascadingMenuContext';
import { MoreMenuItem } from './components/CascadingMenu';

import { AccountStatuses } from 'domain/payments/helpers';
import { actionsList } from './helpers';

import type { AccountType } from 'domain/payments/types.js.flow';

type TProps = {
  data: AccountType,
};

const ContextButton: React$StatelessFunctionalComponent<TProps> = ({ data: { status } }) => {
  const popupState = usePopupState({ variant: 'popover', popupId: 'InsightsContextMenu' });
  const contextMenuActions = useMemo(
    () => ({
      'configurations.company.accounts.grid.contextMenu.edit': {
        onClick: () => {},
      },
      'configurations.company.accounts.grid.contextMenu.viewTransactions': {
        onClick: () => {},
      },
      'configurations.company.accounts.grid.contextMenu.suspend': {
        onClick: () => {},
        isHidden: status === AccountStatuses.suspended,
        color: 'error',
      },
      'configurations.company.accounts.grid.contextMenu.activate': {
        onClick: () => {},
        isHidden: status === AccountStatuses.active,
        color: 'success',
      },
    }),
    [status],
  );

  const optionsList = useMemo(
    () =>
      actionsList
        .map(({ id, defaultMessage, ...rest }) => ({
          id,
          title: { id, defaultMessage },
          ...rest,
          ...contextMenuActions[id],
        }))
        .filter(({ isHidden }) => !isHidden),
    [contextMenuActions],
  );
  return (
    <Stack justifyContent="center" alignItems="center" flex={1} height="100%">
      <IconButton sx={{ fontSize: '22px' }} {...bindTrigger(popupState)}>
        <MoreHorizIcon fontSize="inherit" />
      </IconButton>
      {!!optionsList.length && (
        <Menu
          autoFocus
          transformOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          {...bindMenu(popupState)}
        >
          <CascadingMenuContext.Provider value={{ rootPopupState: popupState, parentPopupState: popupState }}>
            {optionsList.map((option) => (
              <MoreMenuItem option={option} key={option.id} />
            ))}
          </CascadingMenuContext.Provider>
        </Menu>
      )}
    </Stack>
  );
};

export default ContextButton;
