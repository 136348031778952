import { combineReducers, compose, createStore, applyMiddleware } from 'redux';
import routerMiddleware from 'domain/router/redux/routerMiddleware';
import Immutable from 'immutable';
import installDevTools from 'immutable-devtools'; // eslint-disable-line import/no-extraneous-dependencies
import createSagaMiddleware, { END } from 'redux-saga';
import Reactotron from 'reactotron-react-js';
import Cookies from 'js-cookie';

import { getRoles } from 'domain/env/helpers';
import { storage } from 'lib/storage';
import chatMiddlware from 'domain/chat/middlware';
import { setUser, sagaUncaughtErrorHandler } from 'lib/errorLogger';
import { DEFAULT_PANEL_WIDTH } from 'pages/company/LinkedDocumentsPanel/components/StyledComponents';

const __DEV__ = process.env.NODE_ENV === 'development';

installDevTools(Immutable);

const st = storage();
const reactotronEnabled = st.reactotronEnabled().get();
const env = {
  // to be removed
  dokkaToken: st.token().get(),
  role: getRoles(),
  chatToken: st.chatToken().get(),
  chatUser: st.chatUser().get(),
  userId: st.userId().get(),
  isDokkaSupport: st.isDokkaSupport().get() === 'true',
};

const locales = Cookies.get('locale') || "{}";
setUser(st.userGUID().get(), st.userId().get());

const linkedSidebarWidth = window.localStorage.getItem('dokkaLinkedSidebarWidth') || DEFAULT_PANEL_WIDTH;

const view = Immutable.Map({
  documentsWidth: window.localStorage.getItem('dokkaDocumentsWidth'),
  infoBarWidth: window.localStorage.getItem('dokkaInfoBarWidth'),
});

export function configureStore(navigate) {
  let composeEnhancers = compose;
  const initialState = {};

  // add sagaMonitor if reactotron is enabled

  const sagaMiddlewareConfig = {
    onError: sagaUncaughtErrorHandler,
    ...(reactotronEnabled ? { sagaMonitor: Reactotron.createSagaMonitor() } : {}),
  };

  const sagaMiddleware = createSagaMiddleware(sagaMiddlewareConfig);

  if (__DEV__) {
    const devEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
    if (typeof devEnhancers === 'function') {
      composeEnhancers = devEnhancers;
    }
  }
  let storeFactory = createStore;

  // using Reactotron's createStore if Reactotron is enabled
  if (reactotronEnabled && typeof Reactotron.createStore === 'function') {
    storeFactory = Reactotron.createStore;
  }

  const store = storeFactory(
    combineReducers({
      ...require('./env').reducer({
        linkedSidebarWidth: linkedSidebarWidth ? parseInt(linkedSidebarWidth, 10) : 0,
        view,
        ...env,
        ...JSON.parse(locales),
      }),
      ...require('./router').reducer,
      ...require('./companies').reducer,
      ...require('./documents').reducer,
      ...require('./approvals').reducer,
      ...require('./insights').reducer,
      ...require('./textract').reducer,
      ...require('./journal').reducer,
      ...require('./settings').reducer,
      ...require('./chat').reducer,
      ...require('./organization').reducer,
      ...require('./organizationUser').reducer,
      ...require('./googleSearch').reducer,
      ...require('./events').reducer,
      ...require('./dictionary').reducer,
      ...require('./messages').reducer,
      ...require('./devices').reducer,
      ...require('./reconciliation').reducer,
      ...require('./requestTransaction').reducer,
      ...require('./categories').reducer,
      ...require('./dashboard').reducer,
      ...require('./notifications').reducer,
      ...require('./ui').reducer,
      ...require('./payments').reducer,
      form: require('redux-form/lib/immutable/reducer').default,
    }),
    initialState,
    composeEnhancers(applyMiddleware(sagaMiddleware, chatMiddlware(), routerMiddleware(navigate))),
  );

  store.runSaga = sagaMiddleware.run;
  store.close = () => store.dispatch(END);
  return store;
}
