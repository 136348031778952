// @flow
import React, { useCallback } from 'react';
import { type NumberFormatValues, NumericFormat } from 'react-number-format';

import cx from 'classnames';

import { useStyles } from './styled';

type Props = {
  value: number | null,
  onChange: (e: NumberFormatValues) => void,
  required?: boolean,
  readOnly?: boolean,
  name: string,
  className: string,
};

const IntegerInput = React.forwardRef(({ value, onChange: inputOnChange, className, ...rest }: Props, ref) => {
  const classes = useStyles();

  const onChange = useCallback(
    (e: NumberFormatValues) => {
      inputOnChange(e.floatValue || null);
    },
    [inputOnChange],
  );

  return (
    <NumericFormat
      getInputRef={ref}
      thousandSeparator
      isNumericString
      allowNegative={false}
      value={value ?? ''}
      onValueChange={onChange}
      type="text"
      dir="ltr"
      className={cx(classes.integerInput, className)}
      {...rest}
    />
  );
});

export default React.memo(IntegerInput);
