import React from 'react';
import { JsonSchema, isBooleanControl, RankedTester, rankWith, schemaMatches, and } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import { Unwrapped } from '@jsonforms/material-renderers';
import { Grid, Typography, Link } from '@mui/material';

const { MaterialBooleanControl } = Unwrapped;

export const checkBoxWithLink = (props) => {
  const { schema, label } = props;
  const extendedLabel = (
    <>
      {label}&nbsp;
      <Link underline='none' href={schema.link} target='_blank' rel='noopener noreferrer'>
        {schema.link}
      </Link>
    </>
  );

  return <MaterialBooleanControl {...props} label={extendedLabel} />;
};

export const checkBoxWithLinkTester: RankedTester = rankWith(
  10, // priority must be high to overweight standard renderers
  isBooleanControl,
);

export default withJsonFormsControlProps(checkBoxWithLink);
